/*jslint browser: true, nomen: true */
/*global $ */

function PageTimer() {
  var self = this;
  this.getLoadTime = function () {
    var now = new Date().getTime();
    // Get the performance object
    window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
    var timing = performance.timing || {};

    var ms = now - timing.navigationStart;

    return Math.round(ms / 10) / 100;
  };

  this.logToConsole = function () {
    $(window).on('load', function () {
      if(console && console.info) {
        console.info("Client loaded in " + self.getLoadTime() + 's');
      }
    });
  };

  this.append = function (holder) {
    $(window).on('load', function () {
      holder.text(' | LT: ' + self.getLoadTime() + 's');
    });
  };
}


function StickyFooter($wrap, $footer) {
  var $window = $(window);
  this.updateWrapperCSS = function () {
    var footerHeight = $footer.outerHeight();

    $wrap.css({
      marginBottom: -1 * footerHeight,
      paddingBottom: footerHeight
    });
  };

  this.bindOnResize = function () {
    $window.on('resize', this.updateWrapperCSS);

    return this;
  };

  this.update = function () {
    this.updateWrapperCSS();

    return this;
  };
}


/*
 Ensure all external links load as new window/tabs:
 */


function ExternalLinkHandler() {
  var self = this;
  var hostname = document.location.hostname;

  this.matchInternalLink = [new RegExp("^https?:\/\/(.*?)" + hostname)];

  this.addTargetAttribute = function (context) {
    context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
      var anchor = $(this);
      var href = anchor.attr('href');
      var isInternalLink = false;

      for (var i = 0; i < self.matchInternalLink.length; i++) {
        var regex = self.matchInternalLink[i];
        if (href.match(regex)) {
          isInternalLink = true;
        }
      }

      if (!isInternalLink) {
        anchor.attr('target', '_blank').addClass('external-link');
      }
    });
  };

}

function UIBindings() {
  // this.videoModal = function () {
  //   $('#myModal').modal(options)
  // };
  this.bindTooltips = function () {
    $('[data-toggle="tooltip"]').tooltip();
  };
  this.bindPopovers = function () {
    $('[data-toggle="popover"]').popover();
  };

  this.bindSlickCarousels = function () {
    $('[data-slick-carousel-default]').slick({
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: false
    });

    $('[data-slick-carousel-multi]').slick({
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: false,
      responsive: [
        {
          "breakpoint": 1024,
          "settings": {
            "slidesToShow": 3,
            "slidesToScroll": 3,
          }
        },
        {
          "breakpoint": 768,
          "settings": {
            "slidesToShow": 2,
            "slidesToScroll": 2
          }
        },
        {
          "breakpoint": 480,
          "settings": {
            "slidesToShow": 1,
            "slidesToScroll": 1
          }
        }
    ]
    });

    $('[data-toggle="slick-nav"]').on('click', function (e) {
      e.preventDefault();
      var index = $(this).data('index');
      $('[data-slick-carousel-default]').slick('slickGoTo', index);
    });

  };

  this.bindSharing = function () {
    $("[data-share-default]").each(function () {
      var shareHandler = new ShareHandler($(this));
      shareHandler.appendFacebook();
      shareHandler.appendTwitter();
      shareHandler.appendGoogle();
      //shareHandler.appendLinkedIn();
      //shareHandler.appendPinterest();
    });
  };

  this.bindOffCanvas = function() {
      $('[data-toggle="offcanvas"]').click(function () {
        $($(this).data('target')).toggleClass('in');
        $($(this).data('parent')).toggleClass('offcanvas');
        $(this).toggleClass('slide-in');
      });

      $(document).on('click', function(e) {
        e.stopPropagation();
        $('[data-toggle="offcanvas"]').each(function(){
          // $($(this).data('target')).removeClass('in');
        });
      });

    // $(document).on('click', function (e) {
    //   e.stopPropagation();
    //   if ($(target).hasClass('active')) {
    //     $(target).toggleClass('active');
    //     $body.toggleClass('sidebar-open');
    //   }
    // });
  };

  this.bindMagnificpopup = function(){
    $('[data-magnific-video]').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,

      fixedContentPos: false
    });
    $('[data-magnific-gallery]').magnificPopup({
         type: 'image',
         callbacks:
          {
              markupParse: function(template, values, item)
                  {
                      values.description = item.el.data('description');
                  }
          },
          gallery:{
            enabled:true
          },

          image:
              {
                  headerFit: true,
                  captionFit: true,
                  preserveHeaderAndCaptionWidth: false,
                  markup:
                  '<div class="mfp-figure">'+
                      '<figure>'+
                          '<header class="mfp-header">'+
                              '<div class="mfp-top-bar">'+
                                  '<div class="mfp-title"></div>'+
                                  '<div class="mfp-close"></div>'+
                                  '<div class="mfp-decoration"></div>'+
                              '</div>'+
                          '</header>'+
                          '<section class="mfp-content-container">'+
                              '<div class="mfp-img"></div>'+
                          '</section>'+
                          '<footer class="mfp-footer">'+
                              '<figcaption class="mfp-figcaption">'+
                                  '<div class="mfp-bottom-bar-container">'+
                                      '<div class="mfp-bottom-bar">'+
                                          '<div class="mfp-counter"></div>'+
                                          '<div class="mfp-description"></div>'+
                                          '<div class="mfp-decoration"></div>'+
                                      '</div>'+
                                  '</div>'+
                              '</figcaption>'+
                          '</footer>'+
                      '</figure>'+
                  '</div>',
              }
    });
  };

  this.bindMasonary = function () {
  
    $('[data-masonry-default]').masonry({
      itemSelector: '[data-masonry-default] > div',
      columnWidth: '.grid-sizer'
    });
  };

  this.bindSubmittingButtons = function () {
    $(document).on('submit', 'form', function () {
      var submitText = $(this).find('[data-submit-text]');
      var submittingText = $(this).find('[data-submitting-text]');
      var btn = submitText.closest('button');
      submittingText.removeClass('hide');
      submitText.hide();
      btn.prop('disabled', true);
    });
  };

}

function Notifications () {
  var wrapper = $('#notifications-wrapper');
  var div = $('#notifications');
  var wrapperTop = 0;

  this.bindOnScroll = function () {
    wrapper.height(wrapper.height());
    $(window).on('scroll', function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > wrapperTop) {
        div.addClass('fixed');
      } else {
        div.removeClass('fixed');
      }
    });
  };

  this.bindCloseButton = function () {
    $(document).on('click', '[data-toggle="remove"]', function (e) {
      e.preventDefault();
      var notification = $(this).closest('.notification');
      notification.fadeOut(250, function () {
        wrapper.height('auto');
        wrapper.height(wrapper.height());

      });
    });
  };

  this.init = function () {
    if (wrapper.exists()) {
      wrapperTop = wrapper.offset().top;
      this.bindOnScroll();
      this.bindCloseButton();
    }
  };
}

function UIFormBindings(selector) {
  var forms = $(selector);

  this.addValidators = function(){

    Parsley.addValidator('requiredField', {
      requirementType: 'string',
      validate: function (_value, requirement, instance) {
        var $element = $(instance.$element);
        var input = $element.find('[name="' + requirement +'"]:checked, [name="' + requirement +'"]');
        var inputVal = '';
        var isValid = false;
        var inputChecked = false;

        if(input.is(':radio')) {
          isValid = input.filter(function(){
            return $(this).is(':checked');
          }).exists();

        }  else {
          inputVal = input.val();
          isValid = !!$.trim(inputVal);
        }

        console.log($element.data('parsleyGroup'), 'isValid', isValid );
        return isValid; // No section is filled, this validation fails
      }
    });

    Parsley.addValidator('urlstrict', {
      requirementType: 'string',
      validate: function(value, requirement, instance) {
        var regExp = /^(https?|s?ftp|git):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
        return '' !== value ? regExp.test( value ) : false;
      }
    });

    Parsley.addValidator('geocode', {
      requirementType: 'string',
      validate: function(value, requirement, instance) {
        var id = $(instance.$element).data('parsleyGeocode');
        var fields = $('[data-parsley-group]').filter(function () {
          return $(this).data('parsleyGroup') === id && $.trim(this.value) === ""; // remove the $.trim if whitespace is counted as filled
        });

        
        return !fields.exists();
      }
    });

    return this;
  };

  this.validate = function(){
    if (forms.exists()) {
      forms.parsley({
        inputs: Parsley.options.inputs + ',[data-parsley-recaptcha],[data-parsley-honeypot],[data-parsley-required-field],[data-parsley-check-children],[data-parsley-geocode]',
        excluded: Parsley.options.excluded + ', input:hidden:not([data-geo])'
      });
    }

    return this;
  };

  this.bindDropdown = function () {
    $('[data-dropdown] > li').on('click', function (evt) {
        var el = $(this),
            fieldId = el.parent().attr('id'),
            field = $('[name="fields['+ fieldId +']"]'),
            label = $('#'+ fieldId +'-label');

        el.addClass('disabled');
        el.siblings().removeClass('disabled');

        toggleDropdownField({
            field: field,
            value: el.data('text') ? el.data('text').replace('_._', '') : el.data('value'),
            type: el.data('text') ? 'text' : 'hidden',
            placeholder: el.data('placeholder') ? el.data('placeholder') : ''
        });

        if (el.data('value') !== 'Other') {
          field.val(el.data('value'));
        }
        label.html(el.html());
        fieldValidate(el);
    });

    function toggleDropdownField (options) {
        if (options.field.attr('type') === options.type) {
            return;
        }
        options.field.val($.type(options.value) !== 'number' ? options.value : '');
        options.field.attr('type', options.type);
        options.field.focus();
    }

    return this;
  };

  this.bindEvents = function () {
    var inputThrottle;
    $('[name="qty"]').on('change', function () {
      var parent = this;
      clearTimeout(inputThrottle);
      inputThrottle = setTimeout(function () {
        console.log(parent.value, parent.checkValidity());
        if (parent.checkValidity()) {
          $(parent).closest('form').submit();
        }
      }, 500);
    });

    $('[data-password-toggle]').each(function () {
      var toggle = $(this),
      field = toggle.data('passwordToggle');

      togglePasswordField({
        control: toggle,
        field: '#' + field
      });
    });

    this.bindDropdown();

    return this;
  };

  this.bindGeoComplete = function () {
      $(".js-geocode").geocomplete({
          details: ".geocode",
          detailsAttribute: "data-geo",
          blur: false,
          geocodeAfterResult: true,
          types: ["geocode", "establishment"]
      }).bind("geocode:result", function(event, result){
        fieldValidate($(event.target));
      });

      return this;
  };

  function fieldValidate(el) {
    var parsleyGroupEl = el.closest('[data-parsley-group]');
    var form = el.closest('form');
    if (form.exists()) {
      form.parsley().validate(parsleyGroupEl.data('parsleyGroup'));
    }
  }

  function togglePasswordField (options) {
    var settings = $.extend({
      field: "#password",
      control: "#toggle_show_password"
    }, options);

    var control = $(settings.control);
    var field = $(settings.field);

    control.on('click', function () {
      if (field.attr('type') === 'password') {
        field.attr('type', 'text');
      } else {
        field.attr('type', 'password');
      }
    });
  }
}

$.fn.exists = function () {
  return $(this).length > 0;
};


$(function () {

  (new StickyFooter($('#container'), $('#footer'))).update().bindOnResize();

  (new ExternalLinkHandler()).addTargetAttribute($('body'));
  (new PageTimer()).logToConsole();
  (new UIBindings()).bindOffCanvas();
  (new UIBindings()).bindTooltips();
  (new UIBindings()).bindPopovers();
  (new UIBindings()).bindSlickCarousels();
  (new UIBindings()).bindSharing();
  (new UIBindings()).bindMasonary();
  (new UIBindings()).bindMagnificpopup();
  (new UIBindings()).bindSubmittingButtons();
  (new UIFormBindings()).bindDropdown().bindEvents();
  (new Notifications()).init();
});

function initialize(){
    (new UIFormBindings('form')).bindGeoComplete();
}
